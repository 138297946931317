import React, { useContext, useState, useEffect } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { Box, Link } from '@mui/material';
import NavigationContext from '../contexts/NavigationContext';
import ThemeContext from '../contexts/ThemeContext';
import SubMenu from './SubMenu.component';


const MenuItem = ({ item }) => {
  const { setMenuOnHover } = useContext(NavigationContext);
  const browserType = useContext(ThemeContext);
  const [expanded, setExpanded] = useState(false);

  const handleClickExpand = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(!expanded);
  }

  useEffect(() => { 
    if (browserType === 'desktop') {
      setMenuOnHover({
        onHover: expanded,
        showBackground: item.items?.length > 0
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserType, expanded]);

  return (
    <Box 
      className="menu-link-wrapper"
    >
      <Link
        id={item.uiRouterKey}
        href={item.path}
        className="menu-link"
      >
        {item.title} 
        {item.items?.length > 0 && (
          <ArrowDropDown className="menu-expand" onClick={handleClickExpand}/>
        )}
      </Link>
      {item.items?.length > 0 && (
        <SubMenu items={item.items} expanded={expanded} />
      )}
    </Box>
  );
}

export default MenuItem;