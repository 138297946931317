import {
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Box } from '@mui/material';
import { Menu, MotionPhotosAuto } from '@mui/icons-material';
import ThemeContext from '../contexts/ThemeContext';
import NavigationContext from '../contexts/NavigationContext';
import MenuItem from './MenuItem.component';

const Navigation = ({ routes }) => {
  const refs = useRef({});
  const overlay = useRef(null)
  const value = useContext(ThemeContext);
  const { setMenuOnHover } = useContext(NavigationContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const menuItems = useMemo(() =>
    routes.map((item, index) => (
        <li 
          ref={(el) => (refs.current[index] = el)}
          onMouseOver={() => {
            setActiveIndex(index);
            setMenuOnHover({ onHover: true, showBackground: item?.items?.length > 0})
          }}
          onMouseLeave={() => {
            setActiveIndex(-1);
            setMenuOnHover({ onHover: false })
          }}
        >
          <MenuItem item={item} />
        </li>
    )
  ), [routes]);

  useEffect(() => {
    setMenuOnHover({
      onHover: menuOpen,
      showBackground: true,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen]);

  useEffect(() => {
    const current = refs.current[activeIndex];
    const background = overlay.current;
    if (current) {
      const { width } = current?.getBoundingClientRect();
      background.style.left = `${current.offsetLeft}px`;
      background.style.width = `${width}px`;
      background.style.opacity = 1;
    } else {
      background.style.opacity = -1;
    }
  }, [activeIndex, overlay]);

  return (
    <div className="menu">
      { value !== 'desktop' && 
        <Box className="menu-icon">
          <Menu onClick={() => setMenuOpen(!menuOpen)}/>
        </Box>
      }
      <Box className={`menu-items ${menuOpen ? 'open' : 'closed'}`}>
        <ul className="menu-dropdown">
          {menuItems}
        </ul>
        <div ref={overlay} className="overlay"/>
      </Box>
    </div>
  )
}

export default Navigation;