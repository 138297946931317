import { createTheme } from "@mui/material";

let mainTheme = createTheme();

mainTheme = createTheme(mainTheme, {
  mobile: {
    typography: {
      h3: {
        fontSize: '1.5em',
      },
      h5: {
        fontSize: '1em',
      },
    },
    button: {
      height: '3rem',
      fontSize: '0.8rem',
      padding: '0.5rem',
      marginTop: '1rem',
    },
    header: {
      box: {
        height: '60px',
        background: "#EFEFEF",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100vw',
        padding: '0'
      },
      content: {
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'flex-start',
      }
    },
    menu: {
      icon: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: '10px',
        alignSelf: 'center'
      },
      items: {
        closed: {
          display: 'none',
        },
        open: {
          display: 'block',
          position: 'absolute',
          top: '60px',
          width: '100vw',
          left: 0,
          zIndex: 999,
          background: '#FFF',
          padding: 0,
        },
        list: {
          alignItems: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          borderBottom: '1px solid #CCCCCC',
          paddingTop: 0,
          paddingBottom: 0,
        },
        link: {
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          flexWrap: 'wrap',
          alignContent: 'space-between',
          textDecoration: 'none',
        }, 
        expand: {
          marginLeft: 'auto',
          marginTop: 'auto',
          marginBottom: 'auto',
        }
      }
    },
    topSection: {
      box: {
        position: 'absolute',
        zIndex: 1,
        top: 0,
        padding: '1rem',
        width: '100%',
        justifyContent: 'space-around',
      },
    },
    pageContent: {
      box: {
       padding: '15px', 
       textAlign: 'center'
      }
    },
    contentTextImage: {
      box: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '5rem'
      }
    }
  },
  desktop: {
    typography: {
      ...mainTheme.typography,
    },
    navigation: {
      display: 'grid'
    },
    header: {
      box: {
        height: '60px',
        background: "#EFEFEF",
        width: '100vw',
        padding: '0',
        position: 'sticky',
        zIndex: 999,
      },
      content: {
        maxWidth: '1440px',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'flex-start',
      }
    },
    menu: {
      icon: {
        display: 'none',
      },
      items: {
        open: {
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
          padding: 0,
          height: '60px',
          alignItems: 'stretch',
        },
        list: {
          padding: 0,
          width: 'auto',
          marginRight: '20px',
        },
        link: {
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          flexWrap: 'wrap',
          alignContent: 'space-between',
          textDecoration: 'none',
        }, 
        expand: {
          marginLeft: 'auto',
          marginTop: 'auto',
          marginBottom: 'auto',
        }
      }
    },
    topSection: {
      box: {
        position: 'absolute',
        zIndex: 1,
        top: 0,
        margin: '5rem',
        width: '50%',
      },
    },
    pageContent: {
      box: {
       padding: '0 5rem', 
      }
    },
    contentTextImage: {
      box: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '5rem',
      }
    }
  }
});

export default mainTheme;