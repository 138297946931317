import React from 'react';
import { Box } from '@mui/material';
import MenuItem from './MenuItem.component';

const SubMenu = ({ items = [], expanded = false }) => {
  return (
    <Box className={`submenu ${expanded ? 'open' : 'close'}`}>
      { items.map(item => (
          <MenuItem item={item} />
        ))
      }
    </Box>
  );
}

export default SubMenu;