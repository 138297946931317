import { useContext } from 'react';
import { Box } from '@mui/material';
import Card from './Card.component';
import { Carousel } from 'react-responsive-carousel';
import ThemeContext from '../contexts/ThemeContext';
import ArrayKey from '../Utils/ArrayKey.util';

const CardsView = ({ content = {}}) => {
  const browserType = useContext(ThemeContext);
  const { title = '', Card: list = [], content: cardContent = '', id } = content;

  return (
    <Box className={`cards-view cards-view-${id}`}>
      { title && <h3 className="cards-view-title">{title}</h3> }
      <div className="cards-view-content" dangerouslySetInnerHTML={{__html: cardContent}} />
      <div className="cards-container">
        {browserType === 'mobile' && (
          <Carousel showArrows={false} showStatus={false} showThumbs={false} showIndicators={true}>
          {list && list.map((item, index) =>
            <Card key={ArrayKey(index)} card={item} />
          )}
          </Carousel>
        )}
        {browserType === 'desktop' && (
          <>
            {list && list.map((item, index) =>
              <Card key={ArrayKey(index)} card={item} />
            )}
          </>
        )}
      </div>
    </Box>
  )
}

export default CardsView;
