import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Box } from '@mui/material';
import Page from '../components/Page.component';

const initBrowserRouter = (routes, parent = '') => {

  const browserRouter = [];
  routes.forEach((item) => {
    const { path, related = {} } = item;
    const { id: pageId } = related;
    const currentPath = path.replace(`${parent}/`, '').trim();
    browserRouter.push({
      path: item.path === 'home' ? '/' : currentPath,
      element: item ? <Page id={pageId ? pageId : item.id} title={item.title}/> : null,
      title: item.title,
      children: item.items && item.items.length > 0 ? initBrowserRouter(item.items, item.path) : []
    });
  });

  return browserRouter;
};

const Public = ({ routes = [] }) => {
  const [ routers, setRouters ] = useState([]);
  const [ render, setRender ] = useState(false);
  
  useEffect(() => {
    if (routes.length > 0) {
      const router = initBrowserRouter(routes);
      setRouters(router);
    }
  }, [routes]);

  useEffect(() => {
    if (routers.length > 0) {
      setRender(true);
    }
  }, [routers]);

  return(
    <Box id="page-container">
    {render && (
      <RouterProvider router={createBrowserRouter(routers)} />
    )}
    </Box>
  );
  
}

export default Public;
