import React from 'react';
import { Box } from '@mui/material';
import ProductsSectionContent from './ProductsSectionContent.component';

const ProductsSection = ({ data = {}, browserType = 'desktop' }) => {
  const { attributes = {} } = data;
  const { products = {} } = attributes;
  const items = products.data || [];

  return (
    <div className="products-section">
      <h3>Product Highlight</h3>
      <Box className="products-section-container">
      { items.map((item) => (
        <div className="products-section-content-wrapper" key={item.id}>
            <ProductsSectionContent data={item.attributes} />
        </div>
        ))
      }
      </Box>
    </div>
  )
}

export default ProductsSection;