const handleResponse = (response) => {
  const contentType = response.headers.get('content-type');
  const isJSON = contentType.includes('json');
  if (response.ok) {
    return isJSON ? response.json() : response.blob();
  }
  throw new Error(response.statusText);
}

export default class REST {
  constructor() {
    this.mode = 'cors';
    this.defaultHeader = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    };
    this.controller = new AbortController();
  }

  abort() {
    this.controller.abort();
  }

  async _baseFetch ({path = '', payload = {}, callback = () => {}, headers={}, api = process.env.REACT_APP_API_PATH}, method = 'POST') {
    const uri = new URL(`${api}${path}`, process.env.REACT_APP_API_URL);
    if (method === 'GET') {
      if (Object.keys(payload).length > 0) {
        uri.search = new URLSearchParams(payload);
      }
      return await fetch(uri, {
        method,
        mode: this.mode,
        headers: Object.assign(this.defaultHeader, { ...headers }),
      })
      .then(response => handleResponse(response))
      .then(data => data)
      .catch((error) => { 
        console.error(error);
        throw error; 
      });
    } else {
      return await fetch(uri, {
        method,
        mode: this.mode,
        headers: Object.assign(this.defaultHeader, { ...headers }),
        body: JSON.stringify(payload),
      })
      .then(response => handleResponse(response))
      .then(data => data)
      .catch((error) => {
        console.error(error)
        throw error;
      });
    }
  }

  async GET(params) {
    return await this._baseFetch(params, 'GET');
  }

  async POST(params) {
    return await this._baseFetch(params, 'POST');
  }

  async PUT(params) {
    return await this._baseFetch(params, 'PUT');
  }

  async DELETE(params) {
    return await this._baseFetch(params, 'DELETE');
  }

  async UPLOAD(params) {
    return await this._baseFetch(params, 'UPLOAD');
  }
}

