import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Image from './Image.component'

const CardComponent = ({ card }) => {
  const { Icon, content = '', title = '', id } = card;
  return (
    <div className={`card-wrapper card-wrapper-${id}`}>
      <Card variant="outlined" className="card">
        <CardContent>
          { title && <div className="card-title"><h3>{title}</h3></div> }
          { (Icon && Icon.data !== null) && <div className="card-icon"><Image media={Icon} /></div> }
          <div className="card-content" dangerouslySetInnerHTML={{__html: content}} />
        </CardContent>
      </Card>
    </div>
  )
}

export default CardComponent;
