import React from 'react';


const UnderConstruction = () => {
  return (
    <div className="under-construction">
      <div className="logo"><img src="./logo-multipro-ok-green-flat.png" height="100%" width="100%" alt="logo"/></div>
      <div className="under-construction-title">Under Construction</div>
      <div className="under-construction-subtitle">We are working on this page</div>
      <div className="under-construction-subtitle">Please check back later</div>
      <div className="under-construction-image"></div>
    </div>
  );
};

export default UnderConstruction;