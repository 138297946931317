import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Link } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Footer = () => {
  return (
    <Box className="footer">
        <Grid container>
          <Grid item xs={6} className="footer-logo">
            <img src="/logo-multipro-ok-green-flat.png" id="footer-logo" alt="footer-logo"/>
          </Grid>
          <Grid item xs={6} md ={4} />
          <Grid item xs={12} md={2}>
            <List sx={{
              '& .MuiSvgIcon-root, & .MuiTypography-root': {
                fontSize: 'small'
              }
            }}>
              <ListItem disablePadding>
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText>APL Tower, Podomoro City
Jl. Letjen S. Parman No.Kav.28 lantai 16 unit T9
Jakarta 11470</ListItemText>
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText>beli@multipro.co.id</ListItemText>
              </ListItem>
	      <ListItem disablePadding>
		<ListItemIcon>
		  <WhatsAppIcon />
		</ListItemIcon>
		<ListItemText><Link href="https://wa.me/+6281118286128" target="_blank" rel="noopener noreferrer">+628118286128</Link></ListItemText>
	      </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText><Link href="tel:+62216627567">+6221 6627567</Link>, <Link href="tel:+62216691705">+6221 6691705</Link></ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>

    </Box>
  )
}

export default Footer;
