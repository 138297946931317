import React from 'react';
import ApiUri from '../Utils/ApiUri';

const Image = ({ media }) => {
  console.log(media);
  const { data = {} } = media;
  if (!data || !data.attributes) {
    return null;
  }
  const { alternativeText = '', formats = {}, ext = '' } = data.attributes || {};
  if (formats === null) {
    return null;
  }
  const { large = {}, medium = {}, small = {}, thumbnail = {} } = formats;
  const imgUrl = large.hash ? large.hash : medium.hash ? medium.hash : small.hash ? small.hash : thumbnail.hash ? thumbnail.hash : ''
  return (
    <>
      {imgUrl &&
        <img src={`${ApiUri.getApiHost()}/uploads/${imgUrl}${ext}`} alt={alternativeText} />
      }
    </>
  );
}

export default Image;