import Image from './Image.component';

const ProductsSectionContent = ({ data }) => {
  const { name = '', short_description = '', Images = {} } = data;
  const { data: imageData } = Images;
  const highlightedImage = imageData[0] || {};

  return (
    <div className="products-section-content">
      <div className="products-section-content-top">
        <div className="products-section-content-image">
          <Image media={{data: highlightedImage}} />
        </div>
      </div>
      <div className="products-section-content-bottom">
        <div className="products-section-content-title">{name}</div>
        <div className="products-section-content-description" dangerouslySetInnerHTML={{__html: short_description}} />
      </div>
    </div>
  );
}

export default ProductsSectionContent;

