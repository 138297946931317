import { useContext, useMemo }  from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import ThemeContext from '../contexts/ThemeContext';
import ArrayKey from '../Utils/ArrayKey.util';
import mainTheme from '../themes/mainTheme';
import Image from './Image.component';

const TopSectionButton = styled(Button)({
  borderRadius: '32px',
  padding: '10px 20px',
  backgroundColor: '#fff',
  '&:hover' : {
    backgroundColor: '#000',
    color: '#fff'
  }
});

const TopToBottomContent = ({ content, index }) => {
  const browser = useContext(ThemeContext);
  const theme = mainTheme[browser].topSection;
  const {
    Title,
    Content = [],
    Media = {},
    linkTitle,
  } = content;
  const {
    data: mediaData = []
  } = Media;

  const renderContent = useMemo(() => {
    if (Content) {
      let contents = [];
      contents = Content.map((item) => {
        const { children = [] } = item;
        const childMap = children?.map((child) => {
          const { type = '' } = child;
          if (type === 'heading') {
            return <h3>{child.text}</h3>;
          } else {
            return <p>{child.text}</p>;
          }
        })
        return childMap;
      });
      return contents;
    }
    return <div dangerouslySetInnerHTML={{__html: Content}} />;
  }, [Content]);

  const renderButton = useMemo(() => {
    if (linkTitle) {
      return (
        <TopSectionButton variant="outlined" sx={mainTheme[browser].button}>{linkTitle}</TopSectionButton>
      )
    }
  }, [linkTitle, browser]);

  return (
    <Box className={`top-to-bottom-section top-to-bottom-section-${index}`}>
      <Box 
        className="top-to-bottom-section-content"
        sx={theme.box}
      >
        <Typography variant="h3" gutterBottom>{Title}</Typography>
        { renderContent }
        { mediaData?.map((media) => 
          <div className="top-to-bottom-section-image-container" key={ArrayKey}>
            <Image media={media} />
          </div>
        )}
        { renderButton }
      </Box>
    </Box>
  )
}

export default TopToBottomContent;