import { useContext } from 'react';
import { Box } from '@mui/material';
import ThemeContext from '../contexts/ThemeContext';
import ContentTextImage from './ContentTextImage.component';
import mainTheme from '../themes/mainTheme';

const PageContent = ({ contents = [] }) => {
  const browser = useContext(ThemeContext);
  const theme = mainTheme[browser].pageContent;
  const components = [];
  if (contents.length !== 0) {
    contents.forEach((item) => {
      const { __component: component } = item;
      if (component === 'section.text-image') {
        components.push(<ContentTextImage content={item} />)
      }
    })
    return (
      <Box
        sx={theme.box}
      >
        { components }
      </Box>
    )
  }

  return false;
}

export default PageContent;