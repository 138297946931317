import { useContext, useMemo }  from 'react';
import { Box, Typography } from '@mui/material';
import ThemeContext from '../contexts/ThemeContext';
import ArrayKey from '../Utils/ArrayKey.util';
import Image from './Image.component';
import mainTheme from '../themes/mainTheme';
import TopToBottomContent from './TopToBottomContent.component';

const TopSection = ({ content }) => {
  const browser = useContext(ThemeContext);
  const theme = mainTheme[browser].topSection;
  const {
    Title,
    Content = [],
    Media = {},
    topToBottomContent = [],
  } = content;

  const renderContent = useMemo(() => {
    return <div dangerouslySetInnerHTML={{__html: Content}} />;
  }, [Content]);

  return (
    <Box id="top-section" sx={{ position: 'relative' }}>
      <Box id="top-section-image">
          <Image media={Media} />
      </Box>
      <Box 
        id="top-section-content"
        sx={theme.box}
      >
        <Typography variant="h3" gutterBottom>{Title}</Typography>
        { renderContent }
      </Box>
      { topToBottomContent.map((item, index) => 
          <TopToBottomContent content={item} index={index} key={ArrayKey(index)}/> 
        )}      
    </Box>
  )
}

export default TopSection;