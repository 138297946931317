import { useContext, useMemo, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ThemeContext from '../contexts/ThemeContext';
import Image from './Image.component';
import mainTheme from '../themes/mainTheme';


const ContentTextImage = ({ content }) => {
  const browser = useContext(ThemeContext);
  const theme = mainTheme[browser].contentTextImage;
  const typography = mainTheme[browser].typography;
  const { id, Content = [], Media ={}, Title = '', mediaPosition = 'LEFT' } = content;

  const generateContents = useMemo(() => {
    return <div dangerouslySetInnerHTML={{__html: Content}} />;
  }, [Content]);

  return (
    <Box sx={theme.box} className={`content-text-image content-text-image-${id}`}>
      { browser === 'mobile' && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Image media={Media} />
          </Grid>
          <Grid item xs={12}>
              <Typography variant="h3" gutterBottom  sx={typography.h3}>
                {Title}
              </Typography>
              { generateContents }
          </Grid>
        </Grid>
      )}
      { (browser !== 'mobile' && mediaPosition === 'RIGHT') && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h3" gutterBottom  sx={typography.h3}>
              {Title}
            </Typography>
            { generateContents }
          </Grid>
          <Grid item xs={6}>
            <Image media={Media} />
          </Grid>
        </Grid>
      )}
      { (browser !== 'mobile' && mediaPosition === 'LEFT') && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Image media={Media} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3" gutterBottom  sx={typography.h3}>
              {Title}
            </Typography>
            { generateContents }
          </Grid>
        </Grid>
      )}
      { (browser !== 'mobile' && mediaPosition === 'TOP') && (
        <Grid container alignItems="center" spacing={2} justify="center" direction="row">
          <Grid item xs={12} sx={{
            textAlign: 'center'
          }}>
            <Typography variant="h3" gutterBottom  sx={typography.h3}>
              {Title}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{
            textAlign: 'center'
          }}>
            <Image media={Media} />
          </Grid>
          <Grid item xs={12}>
              { generateContents }
          </Grid>
        </Grid>
      )}
      { (browser !== 'mobile' && mediaPosition === 'BOTTOM') && (
        <Grid container alignItems="center" spacing={2} justify="center" align="center" direction="row">
          <Grid item xs={12} sx={{
            textAlign: 'center'
          }}>
            <Typography variant="h3" gutterBottom  sx={typography.h3}>
              {Title}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{
            textAlign: 'center'
          }}>
              { generateContents }
          </Grid>
          <Grid item xs={12}>
            <Image media={Media} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default ContentTextImage;