import { useContext } from 'react';
import Box from '@mui/material/Box';
import Navigation from './Navigation.component';
import ThemeContext from '../contexts/ThemeContext';

const Header = ({ routes = [] }) => {
  const value = useContext(ThemeContext);
  return (
    <Box
      id="header"
    > 
      <Box 
        id="header-content"
      >
        <Box
          id="logo-wrapper"
          sx={{
            marginRight: '20px'
          }}
        >
          <img 
            id="logo" 
            src="/logo-multipro-ok-green-flat.png" 
            alt="multipro.id"
          />
        </Box>
        <Navigation routes={routes}/>
      </Box>
    </Box>);
}

export default Header;