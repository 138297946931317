import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Header from '../components/Header.component';
import Footer from '../components/Footer.component';
import Public from '../routers/Public.router';
import ThemeContext from '../contexts/ThemeContext';
import NavigationContext from '../contexts/NavigationContext';
import browserType from '../utils/browserType';

const Main = ({ routes = [] }) => {
  const [browser, setBrowser]= useState(browserType());
  const [menuOnHover, setMenuOnHover] = useState({
    onHover: false,
    showBackground: false,
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setBrowser(browserType());
    }
    window.addEventListener("resize", handleWindowResize);
  }, []);

  return (
    <ThemeContext.Provider value={browser}>
      <NavigationContext.Provider value={{menuOnHover, setMenuOnHover}}> 
        <div className={`background-shadow ${menuOnHover.onHover && menuOnHover.showBackground ? 'visible' : ''}`}/>
        <Box id="main">
          <Header routes={routes} />
          <Public routes={routes} />
          <Footer routes={routes} />
        </Box>
      </NavigationContext.Provider>
    </ThemeContext.Provider>
  );
}

export default Main;