import { useState, useEffect, useMemo, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Rest from '../Utils/Rest.util';
import TopSection from './TopSection.component';
import CardsView from './CardsView.component';
import ProductsSection from './ProductsSection.component';
import Featured from './Featured.component';
import PageContent from './PageContent.component';
import ThemeContext from '../contexts/ThemeContext';

const restUtil = new Rest();

const Page = ({ id = 1, title = '' }) => {
  const browserType = useContext(ThemeContext);
  const [content, setContent] = useState({});

  useEffect(() => {
    const getContent = async () => {
      const data = await restUtil.GET({
        path: `/pages/${id}`,
        payload: {
          populate: 'deep'
        }
      });
      if (data) {
        setContent(data);
      }

    }
    getContent();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateTopContent = useMemo(() => {
    const { data = {} } = content;
    const { attributes = {} } = data;
    if (!attributes.TopSection) {
      return false;
    }
    
    return <TopSection content={attributes.TopSection} />
  }, [content]);

  const generateCardsView = useMemo(() => {
    const { data = {} } = content;
    const { attributes = {} } = data;
    if (!attributes.Cards) {
      return false;
    }

    return <CardsView content={attributes.Cards} />
    
  }, [content])

  const generateContents = useMemo(() =>{
    const { data = {} } = content;
    const { attributes = {} } = data;

    if (!attributes.Content) {
      return false;
    }
    
    return <PageContent contents={attributes.Content} />
  }, [content]);

  const generateProductsSection = useMemo(() => {
    const { data = {} } = content;
    const { attributes = {} } = data;
    if (!attributes.product_group?.data) {
      return false;
    }
    return <ProductsSection data={attributes.product_group.data} browserType={browserType} />

  }, [content]);

  const generateFeatured = useMemo(() => {
    const { data = {} } = content;
    const { attributes = {} } = data;
    if (!attributes.featured || attributes.featured.length <=0) {
      return false;
    }
    return <Featured data={attributes.featured} />
  }, [content]);

  return (
    <div className={`mtp-page mtp-page-${id}`}>
      { generateTopContent }
      { generateCardsView }
      { generateContents }
      { generateProductsSection}
      { generateFeatured }
      <Outlet />
    </div>
  )
};

export default Page;