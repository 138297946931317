import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Rest from './Utils/Rest.util';
import Main from './Layouts/Main.layout';
import UnderConstruction from './Layouts/UnderConstruction.layout';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import './assets/scss/main.scss';

const restUtil = new Rest();

function App() {
  const [ routeError, setRouteError ] = useState(false);
  const [ routes, setRoutes ] = useState([]);
  const [ render, setRender ] = useState(false);
  
  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const data = await restUtil.GET({ path: '/navigation/render/1?type=TREE' });
        setRoutes(data);
      } catch (err) {
        setRouteError(true);
      }
    };
    fetchRoutes();
  }, []);

  useEffect(() => {
    if (routes?.length > 0) {
      setRender(true);
    }
  }, [routes]);

  return (
    <div id="root">
      <CssBaseline />
      { render && (
        <>
          <Main routes={routes}/>
        </>
      )} 
      {
        routeError && (
          <UnderConstruction />
        ) 
      }
    </div>
  );
}

export default App;
