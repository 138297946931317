const browserType = () => {
  const { userAgent } = navigator;
  if (userAgent.indexOf('Mobile') !== -1 || window.innerWidth <= 768) {
    console.log('userAgent', userAgent);
    return 'mobile';
  } else if (userAgent.indexOf('Tablet') !== -1) {
    return 'tablet';
  }
  return 'desktop';
}

export default browserType;