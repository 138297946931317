import { useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import ThemeContext from '../contexts/ThemeContext';
import Image from "./Image.component";

const Featured = ({ data }) => {
  const browserType = useContext(ThemeContext);

  if (!data) { 
    return null;
  }
  const featured = data[0] || {};
  const { product_groups = {} } = featured;
  const { data: brands = [] } = product_groups;
  console.log(brands);
  return (
    <div className="featured-products">
      <h1>Product Highlights</h1>
      {brands?.map(brand => (
          <div className="featured-product-wrapper" key={`brand-${brand.id}`}>
            <div className="featured-product-brand">
              <h3>{brand.attributes.name}</h3>
            </div>
            <div className="featured-product-items">
              {browserType === 'mobile' && (
                <Carousel showArrows={false} showStatus={false} showThumbs={false} showIndicators={true}>
                  {brand.attributes?.products?.data?.map(product => (
                    <div className="featured-product" key={`product-${product.id}`}>
                      <div className="featured-product-image-wrapper">
                      {
                        product?.attributes?.Images?.data.map(image => (
                          <div className="featured-product-image">
                            <Image media={{data: image}} />
                          </div>
                        ))
                      }
                      </div>
                      <div className="featured-product-content">
                        <div className="featured-product-name">
                          <h4>{product?.attributes?.name}</h4>
                        </div>
                        <div className="featured-product-description" dangerouslySetInnerHTML={{__html: product?.attributes?.full_description}} />
                      </div>
                    </div>
                  ))}
                </Carousel>
              )}
              {
                browserType === 'desktop' && (
                  <>
                    {brand.attributes?.products?.data?.map(product => (
                      <div className="featured-product" key={`product-${product.id}`}>
                        <div className="featured-product-image-wrapper">
                        {
                          product?.attributes?.Images?.data.map(image => (
                            <div className="featured-product-image">
                              <Image media={{data: image}} />
                            </div>
                          ))
                        }
                        </div>
                        <div className="featured-product-content">
                          <div className="featured-product-name">
                            <h4>{product?.attributes?.name}</h4>
                          </div>
                          <div className="featured-product-description" dangerouslySetInnerHTML={{__html: product?.attributes?.full_description}} />
                        </div>
                      </div>
                    ))}
                  </>
                )
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default Featured;