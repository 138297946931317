import React from 'react';

const NavigationContext = React.createContext({
  menuOnHover: {
    onHover: false,
    showBackground: false,
  },
  setMenuOnHover: (onHover, showBackground = false) => {
    return {
      onHover,
      showBackground,
    }
  },
});

export default NavigationContext;